<template>
    <div id="salary-dashboard">
        <errors-bag-list class="d-print-none"></errors-bag-list>

        <b-card no-body class="mb-1 p-1">
            <div class="row">
                <div class="col-12 text-right">
                    <b-button
                        variant="danger"
                        @click="showCalculateModal()"
                        v-show="currentUserViewAllowed"
                        class="mr-1"
                    >
                        Пересчет ЗП
                    </b-button>
                    <b-dropdown text="Ещё" right class="mr-1">
                        <b-dropdown-item @click="printSalary">Печать</b-dropdown-item>
                        <b-dropdown-item
                            v-show="canExportToCSV || currentUserViewAllowed"
                            @click="exportToCSV(export_urls.export_report_to_csv_url)"
                        >
                            Экспорт в CSV
                        </b-dropdown-item>
                        <b-dropdown-item
                            @click="exportToCSV(export_urls.export_commission_report_to_csv_url)"
                            v-show="currentUserViewAllowed"
                        >
                            Экспорт с комиссиями в CSV
                        </b-dropdown-item>
                        <b-dropdown-item :to="{name: 'SalaryBounty'}">Надбавки и штрафы</b-dropdown-item>
                    </b-dropdown>
                    <b-button variant="primary" @click="applyFilters" class="mr-1">
                        <b-icon-arrow-repeat scale="1.2"/>
                    </b-button>
                    <b-button v-b-toggle.filters variant="light">Фильтры</b-button>
                    <small v-if="filtersExists">
                        <b-link @click.prevent="resetFilters">(Сбросить)</b-link>
                    </small>
                </div>
            </div>
            <b-collapse visible id="filters" class="mt-2">
                <b-row>
                    <b-col md="3" v-if="!filters.with_sanitary_inspections">
                        <date-picker
                            v-model="filters.dateFrom"
                            class="w-100"
                            lang="ru"
                            type="date"
                            value-type="YYYY-MM-DD"
                            format="DD.MM.YYYY"
                            placeholder="Выберите дату"
                        />
                    </b-col>
                    <b-col md="3" v-if="!filters.with_sanitary_inspections">
                        <date-picker
                            v-model="filters.dateTo"
                            class="w-100"
                            lang="ru"
                            type="date"
                            value-type="YYYY-MM-DD"
                            format="DD.MM.YYYY"
                            placeholder="Выберите дату"
                        />
                    </b-col>
                    <b-col md="6" v-if="filters.with_sanitary_inspections">
                        <date-picker
                            v-model="filters.month"
                            style="width: 100%"
                            lang="ru"
                            type="month"
                            value-type="YYYY-MM-DD"
                            format="MMMM"
                            placeholder="Выберите месяц"
                        >
                        </date-picker>
                    </b-col>
                    <b-col md="3">
                        <b-form-select v-model='filters.type'>
                            <option value='full'>Полный</option>
                            <option value='without_pass'>Без учета пропусков</option>
                        </b-form-select>
                    </b-col>
                </b-row>
                <b-row class="mt-2">
                    <b-col md="2">
                        <shift-input v-model="filters.shift"/>
                    </b-col>
                    <b-col md="2">
                        <department-select
                            v-model="filters.departments"
                            multiple
                            salary-enabled
                        />
                    </b-col>
                    <b-col md="2">
                        <work-position-select class='full-work-position' v-model='filters.workPosition'/>
                    </b-col>
                    <b-col md="2">
                        <user-input v-model='filters.user' clear-button></user-input>
                    </b-col>
                </b-row>
                <b-row class="mt-2">
                    <b-col cols="12">
                        <b-form-checkbox v-model="filters.with_sanitary_inspections">
                            Учитывать проверки по санитарным нормам
                        </b-form-checkbox>
                    </b-col>
                </b-row>
            </b-collapse>
        </b-card>

        <b-table
            :items="report"
            :fields="fields"
            @row-clicked="showDetail"
            stacked="md"
            no-local-sorting
            responsive
            hover
            head-variant="dark"
            tbody-tr-class="cursor-pointer"
        />

        <salary-detail v-model="salaryDetailShown" :record="record"/>
    </div>
</template>

<script>
import moment from 'moment';

import UserInput from '@components/_common/UserInput';
import ShiftInput from '@components/_common/ShiftInput';
import ErrorsBagList from '@components/_common/ErrorsBagList';
import WorkPositionSelect from '@components/_common/WorkPositionSelect';
import {DepartmentSelect} from '@components';

import DatePicker from 'vue2-datepicker';

import CSV from '@utils/csv';
import ErrorsBag from '@utils/errorsBag';
import lsfMixin from '@utils/localStorageFilterMixin';

import {SALARY_ENDPOINT} from '@utils/endpoints';
import SalaryDetail from './SalaryDetail';

import {ROLE_ACCOUNTANT, ROLE_DIRECTOR} from '@utils/Roles';

const DATE_FORMAT = 'YYYY-MM-DD';
const FILTERS_PATTERN = {
    user: null,
    shift: null,
    type: 'full',
    departments: [],
    operation: null,
    workPosition: null,
    dateTo: moment().format(DATE_FORMAT),
    dateFrom: moment().format(DATE_FORMAT),
    with_sanitary_inspections: false,
    month: null,
};

export default {
    name: 'SalaryDashboard',
    mixins: [lsfMixin],
    components: {
        DatePicker,
        ErrorsBagList,
        UserInput,
        ShiftInput,
        DepartmentSelect,
        WorkPositionSelect,
        SalaryDetail,
    },
    watch: {
        filters: {
            deep: true,
            handler(value) {
                this.watchFilters(value);
            },
        },
    },
    data() {
        const currentUserViewAllowed = this.$auth.user().roles.some(role => [ROLE_ACCOUNTANT, ROLE_DIRECTOR].includes(role))
        return {
            needApplyFilters: false,
            moment,
            ErrorsBag,

            record: null,

            report: [],
            fields: [
                {key: 'index', label: '№'},
                {key: 'user', label: 'Пользователь'},
                {key: 'contractor_name', label: 'Подрядчик'},
                {key: 'work_position', label: 'Должность'},
                {key: 'department', label: 'Подразделение'},
                {
                    key: 'operations_count',
                    label: 'Операции',
                    formatter: (value) => {
                        return parseFloat(value.toFixed(2));
                    },
                },
                {key: 'shifts_count', label: 'Отработано смен'},
                {key: 'hours_count', label: 'Отработано часов'},
                {
                    key: 'salary',
                    label: 'ЗП, руб',
                    formatter: (value) => {
                        return parseFloat(value.toFixed(2));
                    },
                },
                {
                    key: 'operations_salary',
                    label: 'ЗП по операциям, руб',
                    formatter: (value) => {
                        return parseFloat(value.toFixed(2));
                    },
                },
                {
                    key: 'kpi_rate',
                    label: 'KPI, руб',
                    formatter: (value) => {
                        return parseFloat(value.toFixed(2));
                    },
                },
                {
                    key: 'kpi_sanitary_rate',
                    label: 'KPI по сан нормам, руб',
                    formatter: (value, key, item) => {
                        if (!item.kpi_sanitary_rate_in_hour) {
                            return parseFloat(value.toFixed(2));
                        }

                        return `
                            ${parseFloat(value.toFixed(2))}
                            (${parseFloat(item.kpi_sanitary_rate_in_hour.toFixed(2))} ч.)
                        `;
                    },
                },
                {
                    key: 'bounty_fine',
                    label: 'Надбавка/штраф, руб',
                    formatter: (value) => {
                        return parseFloat(value.toFixed(2));
                    },
                },
                {
                    key: 'total',
                    label: 'К выплате, руб',
                    formatter: (value) => {
                        return parseFloat(value.toFixed(2));
                    },
                },
                {
                    key: 'total_sick_day',
                    label: 'Больничный, руб',
                    currentUserViewAllowed,
                    formatter: (value) => {
                        return parseFloat(value.toFixed(2));
                    },
                },
                {
                    key: 'total_holiday',
                    label: 'Отпуск, руб',
                    currentUserViewAllowed,
                    formatter: (value) => {
                        return parseFloat(value.toFixed(2));
                    },
                },
                {
                    key: 'total_pay',
                    label: 'К выплате с НДС, руб',
                    currentUserViewAllowed,
                    formatter: (value) => {
                        return parseFloat(value.toFixed(2));
                    },
                },
                {
                    key: 'total_pay_without_nds',
                    label: 'К выплате без НДС, руб',
                    currentUserViewAllowed,
                    formatter: (value) => {
                        return parseFloat(value.toFixed(2));
                    },
                },
            ],
            export_urls: {
                export_report_to_csv_url: '/export-report-to-csv',
                export_commission_report_to_csv_url: '/export-commission-report-to-csv',
            },
            currentUserViewAllowed,
            canExportToCSV: false,
            salaryDetailShown: false,
        };
    },
    methods: {
        clearDetail() {
            this.record = null;
        },
        showDetail(record) {
            this.clearDetail();

            if (record.user) {
                this.record = record;
                this.record.filters = this.filters;
                if (this.filters.with_sanitary_inspections) {
                    this.record.filters.dateFrom = this.filters.month ? moment(this.filters.month).startOf('month').format(DATE_FORMAT) : null;
                    this.record.filters.dateTo = this.filters.month ? moment(this.filters.month).endOf('month').format(DATE_FORMAT) : null;
                }

                this.salaryDetailShown = true;
            }
        },
        /**
         *
         * @param {Object} data
         * @param {Object[]} data.data
         * @param {String[]} data.uncalculated_dates
         */
        prepareReport(data) {
            const report = data.data;
            if (!this.getFilters.department
                && !this.getFilters.operation
                && !this.getFilters.shift
                && !this.getFilters.user
                && !this.getFilters.workPosition
            ) {
                // Если данных нет уведомляем, что нужен расчет
                if (Object.values(report).length === 0) {
                    this.confirmDialog('Данные за указанный перод отсутствуют. Рассчитать?')
                        .then((value) => value === true ? this.calculateSalary() : '');
                }

                // Если есть не рассчитанные даты, то уведомляем об этом
                if (Object.values(report).length > 0
                    && data.uncalculated_dates.length > 0
                ) {
                    this.confirmDialog(
                        `Данные за даты:
                                ${data.uncalculated_dates.join(', ')}
                            отсутствуют. Рассчитать?`
                    )
                        .then((value) => value === true ? this.calculateSalary() : '');
                }
            }

            let summary = {
                index: 'Итого: ',
                operations_count: 0,
                shifts_count: 0,
                hours_count: 0,
                salary: 0,
                operations_salary: 0,
                detail: null,
                kpi_rate: 0,
                kpi_sanitary_rate: 0,
                kpi_sanitary_rate_in_hour: 0,
                bounty_fine: 0,
                total_sick_day: 0,
                total_holiday: 0,
                total_outstaff_commission: 0,
                total_outstaff_commission_without_nds: 0,
                total_pay: 0,
                total_pay_without_nds: 0,
                total: 0,
            };

            report.forEach((item, i) => {
                const preparedItem = {
                    index: (i + 1),
                    department: item.department_name,
                    work_position: item.user.work_position_name,
                    user: item.user.fullname,
                    user_id: item.user.id,
                    contractor_name: item.contractor_name || null,
                    operations_count: item.operations_count,
                    shifts_count: item.shifts_count,
                    hours_count: item.hours_count,
                    salary: item.salary,
                    operations_salary: item.operations_salary,
                    kpi_rate: item.kpi_rate,
                    kpi_sanitary_rate: item.kpi_sanitary_rate,
                    kpi_sanitary_rate_in_hour: item.kpi_sanitary_rate_in_hour,
                    bounty_fine: item.bounty_fine,
                    total_sick_day: item.total_sick_day,
                    total_holiday: item.total_holiday,
                    total_pay: item.total_pay,
                    total_pay_without_nds: item.total_pay_without_nds,
                    total: item.total,
                };

                summary.kpi_rate += item.kpi_rate;
                summary.kpi_sanitary_rate += item.kpi_sanitary_rate;
                summary.salary += item.salary;
                summary.hours_count += item.hours_count;
                summary.shifts_count += item.shifts_count;
                summary.operations_count += item.operations_count;
                summary.operations_salary += item.operations_salary;
                summary.bounty_fine += item.bounty_fine;
                summary.total_sick_day += item.total_sick_day;
                summary.total_holiday += item.total_holiday;
                summary.total_outstaff_commission += item.total_outstaff_commission;
                summary.total_outstaff_commission_without_nds += item.total_outstaff_commission_without_nds;
                summary.total_pay += item.total_pay;
                summary.total_pay_without_nds += item.total_pay_without_nds;
                summary.total += item.total;

                this.report.push(preparedItem);
            })

            if (this.report.length) {
                summary.hours_count = Math.round(summary.hours_count)
                summary.kpi_rate = Math.round(summary.kpi_rate * 100) / 100;
                summary.kpi_sanitary_rate = Math.round(summary.kpi_sanitary_rate * 100) / 100;
                summary.salary = Math.round(summary.salary * 100) / 100;
                summary.operations_count = Math.round(summary.operations_count);
                summary.operations_salary = Math.round(summary.operations_salary * 100) / 100;
                summary.total_pay = Math.round(summary.total_pay * 100) / 100;
                summary.total_pay_without_nds = Math.round(summary.total_pay_without_nds * 100) / 100;
                summary.total = Math.round(summary.total * 100) / 100;

                this.report.push(summary);
            }
        },
        printSalary() {
            window.print();
        },
        exportToCSV(url) {
            ErrorsBag.discard();

            let filters = this.getFilters;

            let dateFrom = filters.dateFrom ? moment(filters.dateFrom).format(DATE_FORMAT) : null;
            let dateTo = filters.dateTo ? moment(filters.dateTo).format(DATE_FORMAT) : null;
            if (filters.with_sanitary_inspections) {
                dateFrom = filters.month ? moment(filters.month).startOf('month').format(DATE_FORMAT) : null;
                dateTo = filters.month ? moment(filters.month).endOf('month').format(DATE_FORMAT) : null;
            }

            let params = {
                filters: {
                    type: filters.type,
                    user: filters.user ? {id: filters.user.id} : null,
                    shift: filters.shift ? {id: filters.shift.id} : null,
                    department_ids: filters.departments.map(({id}) => id),
                    operation: filters.operation ? {id: filters.operation.id} : null,
                    work_position: filters.workPosition ? {id: filters.workPosition.id} : null,
                    date_to: dateTo,
                    date_from: dateFrom,
                    with_sanitary_inspections: filters.with_sanitary_inspections ? 1 : 0,
                    month: filters.month,
                }
            };

            this.$http
                .get(SALARY_ENDPOINT + url, {params})
                .then(response => {
                    let name =
                        'Зарплата_' + moment(this.filters.dateFrom).format('DD.MM.YYYY') +
                        '-' + moment(this.filters.dateTo).format('DD.MM.YYYY');

                    CSV.download(response.data, name);
                })
                .catch(response => {
                    ErrorsBag.fill(response.body);
                    this.$bvToast.toast('Есть ошибки! Ознакомьтесь со списком вверху страницы', {variant: 'danger'});
                    window.scrollTo({top: 0, behavior: 'smooth'});
                });
        },
        applyFilters() {
            ErrorsBag.discard();

            this.report = [];

            let filters = this.getFilters;

            let dateFrom = filters.dateFrom ? moment(filters.dateFrom).format(DATE_FORMAT) : null;
            let dateTo = filters.dateTo ? moment(filters.dateTo).format(DATE_FORMAT) : null;
            if (filters.with_sanitary_inspections) {
                dateFrom = filters.month ? moment(filters.month).startOf('month').format(DATE_FORMAT) : null;
                dateTo = filters.month ? moment(filters.month).endOf('month').format(DATE_FORMAT) : null;
            }

            const params = {
                filters: {
                    type: filters.type,
                    user: filters.user ? {id: filters.user.id} : null,
                    shift: filters.shift ? {id: filters.shift.id} : null,
                    department_ids: filters.departments.map(({id}) => id),
                    operation: filters.operation ? {id: filters.operation.id} : null,
                    work_position: filters.workPosition ? {id: filters.workPosition.id} : null,
                    date_to: dateTo,
                    date_from: dateFrom,
                    month: filters.month,
                    with_sanitary_inspections: filters.with_sanitary_inspections ? 1 : 0,
                }
            };

            this.$http
                .get(SALARY_ENDPOINT + '/report', {params})
                .then((response) => {
                    if (response.data.unfinished_tasks_exists) {
                        const dates = response.data.unfinished_tasks.map(task => (`(${task.config['date_from']} => ${task.config['date_to']})`))
                        ErrorsBag.add('ВНИМАНИЕ!!! Еще не все даты были расчитаны. \n' + dates);
                    }

                    this.prepareReport(response.data);
                })
                .catch((response) => {
                    ErrorsBag.fill(response.body);
                    this.$bvToast.toast('Есть ошибки! Ознакомьтесь со списком вверху страницы', {variant: 'danger'});
                    window.scrollTo({top: 0, behavior: 'smooth'});
                });
        },
        showCalculateModal() {
            this.confirmDialog('Вы уверены, что хотите пересчитать?')
                .then((value) => value === true ? this.calculateSalary() : '');
        },
        calculateSalary() {
            this.$http
                .post(`${SALARY_ENDPOINT}/report`, {
                    date_from: moment(this.getFilters.dateFrom).format('YYYY-MM-DD'),
                    date_to: moment(this.getFilters.dateTo).format('YYYY-MM-DD')
                })
                .then(() => {
                    this.$bvToast.toast('Ожидайте, идет расчет', {variant: 'success'});
                })
                .catch((error) => {
                    this.$bvToast.toast('Ожидайте, предыдущий расчет еще идет', {variant: 'danger'});
                });
        },
        confirmDialog(message) {
            return this.$bvModal.msgBoxConfirm(
                message,
                {
                    size: 'sm',
                    buttonSize: 'sm',
                    okVariant: 'danger',
                    okTitle: 'Да',
                    cancelTitle: 'Отмена',
                    footerClass: 'p-2',
                    hideHeaderClose: false,
                    centered: true
                }
            );
        }
    },
    created() {
        this.initFilters(this, FILTERS_PATTERN);
    }
}
</script>

<style>
    .cursor-pointer {
        cursor: pointer;
    }
</style>
