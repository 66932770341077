<template>
    <b-modal
        v-model="model"
        @shown="onLoad"
        @hide="onHide"
        :title="title"
        size="lg"
        scrollable
        ok-title="Закрыть"
        ok-only
    >
        <b-table
            responsive
            striped
            :items="salary"
            :fields="salaryFields"
            head-variant="dark"
            no-local-sorting
        >
        </b-table>

        <b-table
            :items="timesheets"
            :fields="timesheetFields"
            :busy="timesheetsIsBusy"
            striped
            responsive
            head-variant="dark"
            no-local-sorting
            show-empty
        >
            <template #empty>
                <div class="text-center my-2">Записи отсутствуют.</div>
            </template>
            <template #table-busy>
                <div class="text-center my-2">
                    <b-spinner variant="dark" class="align-middle"/>
                </div>
            </template>
            <template #cell(outstaff_coefficient)="row">
                {{ row.field.formatter(row.item) }}
            </template>
        </b-table>

        <b-table
            :items="items"
            :fields="fields"
            :busy="itemsIsBusy"
            striped
            responsive
            head-variant="dark"
            no-local-sorting
            foot-clone
            show-empty
        >
            <template #empty>
                <div class="text-center my-2">Записи отсутствуют.</div>
            </template>
            <template #table-busy>
                <div class="text-center my-2">
                    <b-spinner variant="dark" class="align-middle"/>
                </div>
            </template>
            <template #cell(name)="data">
                <b-link
                    :to="{name: 'SpecificationsDashboard', params: {nomenclature_id: data.item.nomenclature_id}}"
                    target="_blank"
                >
                    <span>{{ data.item.name }}</span>
                </b-link>
            </template>

            <template #foot(count)="data">
                <span>{{ items.reduce((total, item) => total + item[data.field.key], 0).toFixed(2) }}</span>
            </template>
            <template #foot(difficulty)="data">
                <span>{{
                        items.reduce((total, item) => total + item[data.field.key] * item.count, 0).toFixed(2)
                    }}</span>
            </template>
            <template #foot(total_pay)="data">
                <span>{{ items.reduce((total, item) => total + item[data.field.key], 0).toFixed(2) }}</span>
            </template>
        </b-table>
    </b-modal>
</template>

<script>
import {USERS_ENDPOINT} from '@utils/endpoints';
import ErrorsBag from '@utils/errorsBag';
import moment from 'moment';

export default {
    name: 'SalaryDetail',
    props: {
        value: {
            type: Boolean,
            default: () => false,
        },
        record: {type: Object, default: () => null},
    },
    computed: {
        title() {
            return 'Детализация: ' + (this.record !== null ? this.record.user : 'отсутствует');
        },
        salary() {
            if (!this.record) {
                return [];
            }

            return [
                {
                    shifts_count: this.record.shifts_count,
                    hours_count: this.record.hours_count,
                    salary: parseFloat(this.record.salary).toFixed(2),
                    operations_salary: parseFloat(this.record.operations_salary).toFixed(2),
                    kpi_rate: parseFloat(this.record.kpi_rate).toFixed(2),
                    total_pay: parseFloat(this.record.total_pay).toFixed(2)
                }
            ];
        },
        model: {
            get: function () {
                return this.value;
            },
            set: function (value) {
                this.$emit('input', value);
            }
        },
    },
    data() {
        return {
            fields: [
                {key: 'name', label: 'Номенклатура'},
                {key: 'count', label: 'Количество'},
                {key: 'difficulty', label: 'Сложность'},
                {key: 'base_cost', label: 'Базовая ставка'},
                {key: 'total_pay', label: 'Итог, руб.'}
            ],
            salaryFields: [
                {key: 'shifts_count', label: 'Отработано смен'},
                {key: 'hours_count', label: 'Отработано часов'},
                {key: 'salary', label: 'ЗП, руб'},
                {key: 'operations_salary', label: 'ЗП по операцим, руб'},
                {key: 'kpi_rate', label: 'KPI, руб'},
                {key: 'total_pay', label: 'К выплате с НДС, руб'}
            ],
            timesheetFields: [
                {key: 'date', label: 'Дата'},
                {key: 'shift', label: 'Смена'},
                {key: 'hours', label: 'Часы'},
                {
                    key: 'outstaff_coefficient',
                    label: 'Коэффициент аутстаффа',
                    formatter(value) {
                        if (value && value.outstaff_coefficients) {
                            const coefficient = value.outstaff_coefficients.find((coefficient) => {
                                return value.date >= moment(coefficient.pivot.valid_from).format('YYYY-MM-DD')
                                    && (
                                        !coefficient.pivot.valid_to
                                        || value.date <= moment(coefficient.pivot.valid_to).format('YYYY-MM-DD')
                                    );
                            });
                            if (coefficient) {
                                return coefficient.coefficient;
                            }
                        }

                        return 1;
                    },
                },
                {
                    key: 'department.name',
                    label: 'Подразделение',
                },
            ],
            timesheets: [],
            items: [],
            timesheetsIsBusy: false,
            itemsIsBusy: false,
        };
    },
    methods: {
        onLoad() {
            this.loadTimesheets();
            this.loadItems();
        },
        loadTimesheets() {
            let params = {
                date_from: this.record.filters.dateFrom,
                date_to: this.record.filters.dateTo,
                without_loading: true,
            };
            this.timesheets = [];
            this.timesheetsIsBusy = true;
            this.$http
                .get(
                    `${USERS_ENDPOINT}/${this.record.user_id}/timesheets`,
                    {params}
                )
                .then((response) => {
                    this.timesheets = response.data;
                })
                .catch((response) => {
                    ErrorsBag.fill(response.body);
                    this.$bvToast.toast('Есть ошибки! Ознакомьтесь со списком вверху страницы', {variant: 'danger'});
                    window.scrollTo({top: 0, behavior: 'smooth'});
                })
                .finally(() => this.timesheetsIsBusy = false);
        },
        loadItems() {
            this.itemsIsBusy = true;
            let params = {
                date_from: this.record.filters.dateFrom,
                date_to: this.record.filters.dateTo,
                without_loading: true,
            };
            this.items = [];
            this.$http
                .get(
                    `${USERS_ENDPOINT}/${this.record.user_id}/productions`,
                    {params}
                )
                .then((response) => {
                    this.items = response.data;
                })
                .catch((response) => {
                    ErrorsBag.fill(response.body);
                    this.$bvToast.toast('Есть ошибки! Ознакомьтесь со списком вверху страницы', {variant: 'danger'});
                    window.scrollTo({top: 0, behavior: 'smooth'});
                })
                .finally(() => this.itemsIsBusy = false);
        },
        onHide() {
            this.items = [];
            this.timesheets = [];
        },
    },
}
</script>
